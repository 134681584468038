export default class EstrattiContoEditItem {
/**
 *
 */
constructor() {
	this.itemID = 0;
	this.scontoEC = null;	
}
	itemID: number;
	scontoEC: number | null;
}

