// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { AnagraficaCommerciali } from "@/models/commerciali/anagraficaCommerciali";
import { TextValueItem } from "@/models/TextValueItem";
import { EstrattiContoItem } from "../../models/estrattiConto/estrattiContoItem";
import EstrattiContoEditItem from "@/models/estrattiConto/estrattiContoEditItem";
import { EseguiComandoRequest } from "../../models/comandi/eseguiComandoRequest";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.ESTRATTI_CONTO;

@Component({})
export default class estrattiContoPage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Pagamenti.ESTRATTICONTO]; }
	editItem: EstrattiContoEditItem = new EstrattiContoEditItem();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyECbalancePositive: boolean = true;

	distributori: TextValueItem[] = [];

	actionsToDo: TextValueItem[] = [
		{ value: null, text: "Esegui su selezione" },
		{ value: 1, text: "Invia tutti gli EC non inviati" },
		{ value: 2, text: "Invia EC ai selezionati" },
		{ value: 3, text: "Sollecito EC ai selezionati" },
		{ value: 4, text: "Ripeti generazione EC selezionati" },
		{ value: 5, text: "Salda adesioni per EC selezionati" },
		{ value: 6, text: "Apri Report Completo Periodo Specificato" },
		{ value: 7, text: "Apri Report Completo Anno Specificato" }
	];

	selectedActionToDo: number = null;

	createUpdatebankStatements: TextValueItem[] = [{ value: null, text: "Seleziona periodo" }];

	selectedCreateUpdatebankStatement: string = null;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: this.$i18n.t("generico.numEC").toString(), width: "4%", type: "numeric-int", filterType: "numeric-int", headerCell: "myHeaderCellTemplate", editHide: true, export: true, format: "{0:#}" },

			{ field: "anno", title: this.$i18n.t("generico.anno").toString(), headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: "5%", editHide: true, format: "{0:#}" },
			{ field: "mese", title: this.$i18n.t("generico.mese").toString(), headerCell: "myHeaderCellTemplate", type: "numeric-int", filterType: "numeric-int", filterable: true, export: true, width: "5%", editHide: true, format: "{0:#}" },
			{
				field: "distributoreID",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				values: this.distributori,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				sortBy: "distributore",
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
				editHide: true,
				width: "11%"
			},
			{
				field: "distributore",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				hidden: true,
				editHide: true,
				export: true,
				width: "1%"
			},
			{
				field: "numeroPratiche",
				title: this.$i18n.t("generico.numeroPratiche").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int",
				format: "{0:n0}",
				editHide: true,
				export: true
			},
			{
				field: "daPagare",
				title: this.$i18n.t("generico.daPagare").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totalePagato",
				title: this.$i18n.t("generico.totalePagato").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "totaleSconto",
				title: this.$i18n.t("generico.totaleSconto").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "scontoEC",
				title: this.$i18n.t("generico.scontoEC").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "acconto",
				title: this.$i18n.t("generico.acconto").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				export: true
			},
			{
				field: "saldo",
				title: this.$i18n.t("generico.saldo").toString(),
				width: "5%",
				headerCell: "myHeaderCellTemplate",
				type: "numeric",
				filterType: "numeric",
				format: "{0:n2}",
				editHide: true,
				export: true
			},
			{
				field: "dataEC",
				title: this.$i18n.t("generico.dataEC").toString(),
				width: "5%",
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "dataInvio",
				title: this.$i18n.t("generico.dataInvio").toString(),
				width: "5%",
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "dataSollecito",
				title:  this.$i18n.t("generico.dataSollecito").toString(),
				width: "5%",
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				editHide: true,
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "itemID",
				title: " ",
				width: "5%",
				type: "renderfunction",
				renderFunction: this.renderCellEstrattoContoRep,
				editHide: true
				// template: function(dataItem) {
				// 	return "<a href='/CMSPages/Webins/WI_EC.aspx?idEC=" + dataItem.ItemID + "' target='_blank'>Apri</a>";
				// }
			},
			{
				field: "dataInvioGreen",
				title: " ",
				width: "5%",
				filterable: false,
				headerCell: "myHeaderCellTemplate",
				type: "img-datainvio-ec",
				editHide: true
				// template: function(dataItem) {
				// 	return dataItem.DataInvio == null ? "" : "<span class='pallinoVerde'></span>";
				// }
			},
			{
				field: "sollecito",
				title: " ",
				width: "5%",
				hidden: false,
				filterable: false,
				type: "img-sollecito-ec",
				headerCell: "myHeaderCellTemplate",
				editHide: true,
				export: true
			},

			{ field: "_actions", title: " ", width: "5%", cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
			{ field: "_ck_select", title: " ", width: "5%", hidden: this.canEdit == false, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	created() {
		var self = this;
		var anagraficaDistributori = this.getAnagraficaDistributori();

		let d = new Date(),
			anno = d.getFullYear(),
			mese = d.getMonth() + 1,
			nomeMesi = [
				"generico.gennaio",
				"generico.febbraio",
				"generico.marzo",
				"generico.aprile",
				"generico.maggio",
				"generico.giugno",
				"generico.luglio",
				"generico.agosto",
				"generico.settembre",
				"generico.ottobre",
				"generico.novembre",
				"generico.dicembre"
			];

		for (var i = 0; i < 10; i++) {
			let annoA = anno;
			let meseA = mese - i;
			if (meseA <= 0) {
				meseA += 12;
				annoA -= 1;
			}
			this.createUpdatebankStatements.push({ value: `${annoA}_${meseA}`, text: `${this.$i18n.t(nomeMesi[meseA - 1])} ${annoA}` });
		}

		this.filter.filters.push({
			field: "saldo",
			operator: "neq",
			value: "!!n=0"
		});

		this.sort = [{ field: "anno", dir: "desc" }, { field: "mese", dir: "desc" }];

		Promise.all([anagraficaDistributori])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() { }

	@Watch("showOnlyECbalancePositive")
	onShowOnlyECbalancePositiveChnage(newValue: boolean) {
		const field = "saldo";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "neq",
				value: "!!n=0"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function (item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	renderCellEstrattoContoRep(data: string) {
		var result = `<a href='${api.endpoint.REPORT}/reporting/pdf/estrattoContoDistributore/${data}' target='_blank' style='color: #5cb85c;'>Apri</a>`;

		return result;
	}

	getAnagraficaDistributori() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.ALL_DISTRIBUTORI)
			.then(res => {
				self.distributori = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT, null, null, "Estratti Conto");
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: EstrattiContoItem) {
		var self = this;

		self.editItem = Object.assign(new EstrattiContoEditItem(), item);
		let dis = this.distributori.find(function (ds) {
			return ds.value == item.distributoreID;
		});
		self.editTitle = item.distributoreID.toString();
		if (dis) {
			self.editTitle = dis.text;
		}
		self.editSubTitle = `${item.anno} - ${item.mese}`;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;

		api.updateEstrattoConto(_ENDPONT, this.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.estrattoConto") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	eseguiCreateUpdatebankStatement() {
		var self = this;
		if (this.selectedCreateUpdatebankStatement) {
			let message = self.$createElement("div", {
				domProps: { innerHTML: `<h5>Periodo: ${this.selectedCreateUpdatebankStatement.replace("_", " - ")}</h5>${self.$i18n.t("messaggio.confermaRicalcoloEC")}` }
			});
			let title = this.$i18n.t("titolo.confermaRicalcolaEC").toString();

			self.$bvModal
				.msgBoxConfirm([message], {
					title: title,
					okVariant: "warning",
					okTitle: self.$i18n
						.t("btn.si")
						.toString()
						.toUpperCase(),
					cancelTitle: self.$i18n
						.t("btn.no")
						.toString()
						.toUpperCase(),
					cancelVariant: "outline-theme",
					footerClass: "",
					headerBgVariant: "warning"
				})
				.then(value => {
					if (value) {
						let data: EseguiComandoRequest = {
							comando: "CreaEC",
							elenco: [],
							parametri: [this.selectedCreateUpdatebankStatement]
						};

						api.eseguiComando(data)
							.then(res => {
								this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
									variant: "success",
									title: this.$i18n.t("generico.azioneeseguita").toString(),
									solid: true
								});

								self.getData();
							})
							.catch(err => {
								console.log(err);
							});
					}
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noPeriodsSelected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	eseguiSuSelezione() {
		var self = this;
		if (this.selectedActionToDo) {
			let cmd = this.selectedActionToDo;
			if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
				let elementToDoAction: number[] = (this.gridData.data as EstrattiContoItem[])
					.filter(function (item) {
						return item._ck_select == true;
					})
					.map(function (el) {
						return el.itemID;
					});

				let actionSelected = this.actionsToDo.find(function (ac) {
					return ac.value == self.selectedActionToDo;
				});
				let message = self.$createElement("div", { domProps: { innerHTML: `<h5>Comando: ${actionSelected.text}</h5>${self.$i18n.t("messaggio.confirmExecuteCommand")}` } });
				let title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.executeCommand")]).toString();

				var esegui = "";
				if (cmd == 1) esegui = "InviaEcTutti";
				if (cmd == 2) esegui = "InviaEcSelezionati";
				if (cmd == 3) esegui = "SollecitoEcSelezionati";
				if (cmd == 4) esegui = "RipetiCreazioneSelezionati";
				if (cmd == 5) esegui = "SaldaSelezionati";
				if (cmd == 6 || cmd == 7) {
					var pr = this.selectedCreateUpdatebankStatement;
					if (!pr) {
						this.$bvToast.toast(this.$i18n.t("generico.noPeriodsSelected").toString(), {
							variant: "warning",
							title: this.$i18n.t("generico.warning").toString(),
							solid: true
						});
						return;
					}
					var url = "/CMSPages/Webins/WI_EC.aspx?pr=" + pr;
					if (cmd == 7) url += "&ac=1";

					//TODO: quando ci sarà la pagina dei report, puntare ad essa con il link corretto
					// $("<a>")
					// 	.attr("href", url)
					// 	.attr("target", "_blank")[0]
					// 	.click();
					return;
				}

				self.$bvModal
					.msgBoxConfirm([message], {
						title: title,
						okVariant: "warning",
						okTitle: self.$i18n
							.t("btn.si")
							.toString()
							.toUpperCase(),
						cancelTitle: self.$i18n
							.t("btn.no")
							.toString()
							.toUpperCase(),
						cancelVariant: "outline-theme",
						footerClass: "",
						headerBgVariant: "warning"
					})
					.then(value => {
						if (value) {
							let data: EseguiComandoRequest = {
								comando: esegui,
								elenco: elementToDoAction,
								parametri: []
							};

							api.eseguiComando(data)
								.then(res => {
									this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
										variant: "success",
										title: this.$i18n.t("generico.azioneeseguita").toString(),
										solid: true
									});

									self.getData();
								})
								.catch(err => {
									console.log(err);
								});
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}

}
